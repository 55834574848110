// COMPONENTS
import { getToken } from "../utils/common";
import { theme_color } from "../theme";

// OTHERS
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import _ from "lodash";

export const PUBLIC_ROUTES = [
  "/",
  "/login",
  "/register",
  "/forgot-password",
  "/terms",
  "/reset-password",
];

export const BREAK_CSS_PDF_CLASS = "break-page-pdf";

export const IsActiveFreeTrial = (userData) => {
  const today = new Date().getTime();
  const free_trial_date = new Date(
    userData?.owner?.next_renew_date || userData?.next_renew_date
  ).getTime();

  if (
    free_trial_date < today &&
    (userData?.owner?.is_free_trial || userData?.is_free_trial)
  ) {
    return false;
  }
  return true;
};

export const IsCurrentPlanActive = (activePlan = {}, is_success, userData) => {
  const today = new Date().getTime();
  const next_billing_date = new Date(activePlan?.next_biiling_date).getTime();

  if (next_billing_date < today && activePlan) {
    return false;
  }
  if (
    is_success &&
    !activePlan?.next_biiling_date &&
    !(userData?.owner?.is_free_trial || userData?.is_free_trial)
  ) {
    return false;
  }
  return true;
};

/**
 ** Check Private Routes for login users and also check free trial and active plan
 * @param {ReactDOM} Component Component of React
 * @param {Array} role Add Roles that are allowed
 */
export const PrivateRoute = ({ component: Component, role = [], ...rest }) => {
  const navigate = useNavigate();
  const authToken = getToken();

  const { data: userData = {}, is_success } = useSelector(
    (state) => state.profile.user
  );
  useEffect(() => {
    if (Object.keys(userData).length) {
      if (
        !IsActiveFreeTrial(userData) ||
        !IsCurrentPlanActive(userData?.plan_details, is_success, userData)
      ) {
        navigate("/subscriptions");
        // return () => navigate("/subscriptions");
      }
    }
    if (userData?.role && !role.includes(userData?.role)) {
      navigate("/");
    }
  }, [is_success, navigate, role, userData]);
  return authToken ? <Component /> : () => navigate("/login");
};

/**
 ** Check Login page Rout and and navigate if already login
 * @param {ReactDOM} Component Component of React
 */
export const LoginPreventRoute = ({ component: Component, ...rest }) => {
  const navigate = useNavigate();
  const authToken = getToken();
  // const isRemember = localStorage.getItem("isRemember");

  useEffect(() => {
    if (
      authToken !== null
      // && isRemember !== null
    ) {
      navigate("/dashboard");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <Component />;
};

/**
 ** Check Role Page page access and return if access is granted
 * @param {ReactDOM} Component Component of React
 * @param {Array} role Add Roles that are allowed
 */
export const RoleBasePreventRoute = ({ component: Component, role = [] }) => {
  const navigate = useNavigate();

  const { data: userData = {} } = useSelector((state) => state.profile.user);

  if (userData?.role && !role.includes(userData?.role)) {
    navigate("/");
  }

  return <Component />;
};

export const BOX_CSS = {
  minHeight: "100px",
  maxHeight: { md: "500px" },
  height: "100%",
  backgroundColor: theme_color.white_color,
  borderRadius: "15px",
  marginBottom: "20px",
};


export const formatText = (text) => {
  if (!_.isString(text)) return;

  return text
    .replace(/([a-z])([A-Z])/g, "$1 $2") // camel case words
    .replace(/_/g, " ") //  underscores
    .replace(/\b\w/g, (char) => char.toUpperCase()); // Capitalize the first letter of each word
};



/**
 ** Change data file name and change if file name is not present and return name
 * @param {Object} dataSource Object of Data source object
 */
export const transferFileName = (dataSource) => {
  if (dataSource?.file_name) return dataSource?.file_name;

  const modifiedName = dataSource?.file?.slice(
    dataSource?.file.lastIndexOf("/") + 1,
    dataSource?.file.length - 4
  );
  return modifiedName;
};

/**
 ** Format Make form Data
 * @param {Object} data data To change Into Form data
 */
export const TransferFormData = (data) => {
  const formData = new FormData();

  Object.entries(data).forEach((entry) => {
    formData.set(entry[0], entry[1]);
  });

  return formData;
};

export const USERROLES = [
  {
    name: "Admin",
    value: "ADMIN",
  },
  {
    name: "Member",
    value: "MEMBER",
  },
];

export const USER_ROLE = {
  ADMIN: "ADMIN",
  MEMBER: "MEMBER",
};

export const PRICE_PLANS = [
  {
    title: "Small Business Plan",
    price: 106,
    id: 1,
    plan: "Basic",
    name: "SMALL",
    image: "/small-plan.png",
    features: [
      {
        text: "1 Admin User",
        info: "Admin can upload data, create users and insightful dashboards as well as in depth written reports and scheduled reports.",
        is_active: true,
      },
      {
        text: "3 Basic Users",
        info: "Basic users can create insightful dashboards as well as in depth written reports and scheduled reports.",
        is_active: true,
      },
      { text: "Unlimited Data Uploads", is_active: true },
      { text: "Unlimited Inquiries", is_active: true },
      { text: "Unlimited Written Reports", is_active: true },
      { text: "Upto 5 Scheduled Reports", is_active: true },
      { text: "Influence future Development", is_active: false },
      { text: "Dedicated Account Manager", is_active: false },
    ],
  },
  {
    title: "Medium Business Plan",
    price: 249,
    id: 2,
    plan: "Advance",
    name: "MEDIUM",
    image: "/medium-plan.png",
    features: [
      {
        text: "3 Admin Users",
        info: "Admins can upload data, create users and insightful dashboards as well as in depth written reports and scheduled reports.",
        is_active: true,
      },
      {
        text: "10 Basic Users",
        info: "Basic users can create insightful dashboards as well as in depth written reports and scheduled reports.",
        is_active: true,
      },
      { text: "Unlimited Data Uploads", is_active: true },
      { text: "Unlimited Inquiries", is_active: true },
      { text: "Unlimited Written Reports", is_active: true },
      { text: "Unlimited Scheduled Reports", is_active: true },
      { text: "Influence future Development", is_active: true },
      { text: "Dedicated Account Manager", is_active: false },
    ],
  },

  {
    title: "Enterprise Level Plan",
    is_contact_us: true,
    id: 3,
    plan: "pro",
    name: "ENTERPRISE",
    features: [
      { text: "Custom number of Admin Users", is_active: true },
      { text: "Custom number of Basic Users", is_active: true },
      { text: "Unlimited Data Uploads", is_active: true },
      { text: "Unlimited Inquiries", is_active: true },
      { text: "Unlimited Written Reports", is_active: true },
      { text: "Unlimited Scheduled Reports", is_active: true },
      { text: "Influence future Development", is_active: true },
      { text: "Dedicated Account Manager", is_active: true },
    ],
  },
];

// ==== CLIENT CONTACT AND SUPPORT MAIL DATA =================
export const CLIENT_INFO = {
  support_mail: "support@yourdataanalyst.com",
  contact: "+447832941581",
};
