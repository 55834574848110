import { Box, List, ListItem } from "@mui/material";
import html2pdf from "html2pdf.js";
import _ from "lodash";
import { BREAK_CSS_PDF_CLASS } from "../const";
import axios from "axios";
import { loadCustomFont } from "./fontFamilyPdf";
import { PDFDocument } from "pdf-lib";

// ==================== Define options ====================
const PDFOPTIONS = {
  margin: [40, 0, 20, 0],
  filename: "download.pdf",
  image: { type: "jpeg", quality: 0.78 },
  html2canvas: { scale: 2.5 },
  pagebreak: {
    // mode: ["css", "avoid-all"],
    mode: "css",
    after: `.${BREAK_CSS_PDF_CLASS}`,
  },
  jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
};

const loadImage = (url) => {
  return new Promise((resolve) => {
    const img = new Image();
    img.setAttribute("crossOrigin", "anonymous");
    img.onload = () => resolve(img);
    img.src = url;
  });
};

const addPageNumbers = async (pdf) => {
  try {
    const getPdfNumber = pdf.internal.getNumberOfPages();
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = pdf.internal.pageSize.getHeight();
    const totalPages = getPdfNumber + 1;

    for (let i = 1; i < totalPages; i++) {
      pdf.setPage(i);

      // ======== Add Footer Page Number =================
      pdf.setFontSize(10);
      pdf.text(
        `Page ${i} of ${getPdfNumber}`,
        pdfWidth / 2 - 10,
        pdfHeight - 8
      );
    }

    return pdf;
  } catch (errors) {
    console.error("🚀 ~ errors in add page number:", errors);
  }
};

const elementRefData = (data) => {
  const container = document.createElement("div");

  const addMargin = process.env.NODE_ENV === "development" ? 8.8 : 1.65;
  for (let index = 0; index < data.length; index++) {
    const element = data[index];

    if (!element) continue;

    element.style.marginTop = `${index * addMargin}px`;

    container.appendChild(element.cloneNode(true));
  }
  return container;
};

const loadImageWithResizing = (url, targetWidth, targetHeight) => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.crossOrigin = "anonymous";

    img.onload = () => {
      // Create a canvas
      const canvas = document.createElement("canvas");
      canvas.width = targetWidth;
      canvas.height = targetHeight;

      const ctx = canvas.getContext("2d");
      ctx.drawImage(img, 0, 0, targetWidth, targetHeight);

      // Convert the canvas to a data URL
      const resizedImageUrl = canvas.toDataURL("image/png");
      resolve(resizedImageUrl);
    };

    img.onerror = (error) => reject(error);
    img.src = url; // Set the source to the URL
  });
};

// ======== Add Big Logo , Template Name , Type of Report in First Page =================================
const addFirstpageContent = async (
  pdf,
  reportName,
  is_dashboard,
  companyLogo
) => {
  const pdfWidth = pdf.internal.pageSize.getWidth();
  const pdfHeight = pdf.internal.pageSize.getHeight();
  // Add a blank page as the first page
  pdf.insertPage(1); // Inserts a blank page at index 1
  pdf.setPage(1); // Set focus to the first page

  // const bigLogoUrl = `${window.location.origin}/final-logo-01.png`;
  const bigLogoUrl = companyLogo
    ? `${process.env.REACT_APP_API_BASE_URL}${companyLogo}`
    : `${window.location.origin}/final-logo-01.png`;

  const bigLogoImg = await loadImage(bigLogoUrl);

  // Resize the logo (set desired width and height here)
  const targetWidth = companyLogo ? bigLogoImg.width / 2 : bigLogoImg.width; // Example: resize to 60px wide
  const targetHeight = companyLogo ? bigLogoImg.height / 2 : bigLogoImg.height; // Example: resize to 20px tall

  const resizeLogo = await loadImageWithResizing(
    bigLogoUrl,
    targetWidth,
    targetHeight
  );

  const logoHeight = 40;
  // Calculate width based on aspect ratio
  const aspectRatio = bigLogoImg.width / bigLogoImg.height;
  const logoWidth = logoHeight * aspectRatio; // Adjust width automatically

  pdf.addImage(
    resizeLogo,
    "PNG",
    pdfWidth / 2 - logoWidth / 2,
    pdfHeight / 2 - 40,
    logoWidth,
    logoHeight,
    "someAlias",
    "FAST"
  );

  if (reportName) {
    pdf.setFontSize(20);
    pdf.text(`${reportName}`, pdfWidth / 2, pdfHeight / 2 + 10, {
      align: "center",
    });

    pdf.text(
      `${!is_dashboard ? "Written Report" : "Dashboard"}`,
      pdfWidth / 2,
      pdfHeight / 2 + 10 + 10,
      { align: "center" }
    );
  }
  // // Move back to the page 2
  pdf.setPage(2); // Set focus to the second page, where content starts

  return pdf;
};

// ============== Add Top Report name and Right side logo =============================
const addLogoAndReportName = async (
  pdf,
  reportName,
  is_dashboard,
  companyLogo
) => {
  const totalPages = pdf.internal.getNumberOfPages() + 1;

  const logoUrl = companyLogo
    ? `${process.env.REACT_APP_API_BASE_URL}${companyLogo}`
    : `${window.location.origin}/logo.png`;

  const logoImg = await loadImage(logoUrl);
  const reportText = `${reportName} ${
    !is_dashboard ? "Written" : "Dashboard"
  } Report`;
  const logoHeight = 20;
  // Calculate width based on aspect ratio
  const aspectRatio = logoImg.width / logoImg.height;
  const logoWidth = logoHeight * aspectRatio; // Adjust width automatically

  // Resize the logo (set desired width and height here)
  const targetWidth = companyLogo ? logoImg.width / 2 : logoImg.width; // Example: resize to 60px wide
  const targetHeight = companyLogo ? logoImg.height / 2 : logoImg.height; // Example: resize to 20px tall
  const resizedLogo = await loadImageWithResizing(
    logoUrl,
    targetWidth,
    targetHeight
  );

  for (let i = 1; i < totalPages; i++) {
    pdf.setPage(i);
    const pdfWidth = pdf.internal.pageSize.getWidth();
    // ======== Add Template and Report Report name ========
    if (reportName) {
      pdf.setFontSize(12);
      pdf.text(5, 15, reportText);
    }

    // ======= Add Right size Small Logo =================
    pdf.addImage(
      resizedLogo,
      "PNG",
      pdfWidth - logoWidth - 10,
      3,
      logoWidth,
      logoHeight
    );

    // ======= Draw a Bottom Line =================
    pdf.setLineCap(2);
    pdf.setDrawColor(128, 128, 128); // draw black lines
    pdf.line(0, 25, pdfWidth, 25); // add line
  }

  return pdf;
};
/**
 ** Format and return pdf response
 * @param {Array} data data to generate pdf
 * @param {String} reportName Report name or Template Name
 * @param {Boolean} is_dashboard True/False make pdf is Dashboard or written reports
 * @param {String} profile_logo  Path fo company add logo
 */
export const GeneratePdfContentForDashboard = async (
  data,
  reportName,
  is_dashboard,
  profile_logo
) => {
  async function generatePdfBlob(element, index) {
    return new Promise((resolve, reject) => {
      html2pdf()
        .set(PDFOPTIONS)
        .from(element)
        .toPdf()
        .get("pdf")
        .then(async (pdf) => {
          // ========= Set Custom Ballo Bhai Font in Pdf =========
          loadCustomFont(pdf);
          pdf.setFontType("normal");
          pdf.setFont("BalooBhai");
          // ========= Set Custom Ballo Bhai Font in Pdf =========

          if (index === 0) {
            pdf = await addFirstpageContent(
              pdf,
              reportName,
              reportName,
              profile_logo
            );
          }
          pdf = await addLogoAndReportName(
            pdf,
            reportName,
            is_dashboard,
            profile_logo
          );

          return pdf;
        })
        .outputPdf("blob") // Specify output as Blob
        .then(async (pdfBlob) => {
          resolve(pdfBlob); // Return the generated Blob
        })
        .catch((error) => {
          reject(error); // Handle errors
        });
    });
  }

  const dataSliceCount = 20;
  const totalGetSlicesCount = Math.ceil(data?.length / dataSliceCount);
  const pdfFiles = [];
  for (let i = 0; i < totalGetSlicesCount; i++) {
    const sliceData = data.slice(i * dataSliceCount, (i + 1) * dataSliceCount);
    const container = elementRefData(sliceData);

    const pdfBlob = await generatePdfBlob(container, i);
    pdfFiles.push(pdfBlob);
  }

  // Create a new PDF document to store the merged result
  const mergedPdfDoc = await PDFDocument.create();

  for (let i = 0; i < pdfFiles.length; i++) {
    // Convert Blob to ArrayBuffer
    const pdfBytes = await pdfFiles[i].arrayBuffer();
    const existingPdfDoc = await PDFDocument.load(pdfBytes); // Load the external PDF using pdf-lib

    // Copy all pages from the existing PDF
    const copiedPages = await mergedPdfDoc.copyPages(
      existingPdfDoc,
      existingPdfDoc.getPageIndices()
    );

    // Add copied pages to the merged PDF
    copiedPages.forEach((page) => mergedPdfDoc.addPage(page));
  }

  const totalPages = mergedPdfDoc.getPageCount();

  const dashboardCondition =
    is_dashboard && process.env.NODE_ENV !== "development";

  if (dashboardCondition) {
    mergedPdfDoc.removePage(totalPages - 1);
  }

  const totalPageCount = totalPages - (dashboardCondition ? 1 : 0);

  mergedPdfDoc.getPages().forEach(async (page, index) => {
    const { width, height } = page.getSize();

    const pageNumberText = `Page ${index + 1} of ${totalPageCount}`;

    // Draw the page number at the bottom center of the page
    page.drawText(pageNumberText, {
      x: width / 2 - 30,
      y: height - height + 20,
      size: 9,
    });
  });

  const mergedPdfBytes = await mergedPdfDoc.save();

  // Create a Blob from the merged PDF bytes and trigger download
  const blob = new Blob([mergedPdfBytes], { type: "application/pdf" });
  // const link = document.createElement("a");
  // link.href = URL.createObjectURL(blob);
  // link.download = "merged.pdf";
  // link.click();
  return blob;
};

/**
 ** *  Format and return pdf response for inquiry module
 * @param {Array} data data to generate pdf
 */
export const GenerateInquiryContentBlobForSendMail = async (data) => {
  const container = elementRefData(data);

  // Convert HTML to PDF
  const dataRes = await html2pdf()
    .from(container)
    .set({
      ...PDFOPTIONS,
      margin: [40, 10, 0, 10],
      html2canvas: { scale: 3 },
      image: { type: "jpeg", quality: 0.8 },
    })
    .toPdf()
    .get("pdf")
    .then(async (pdf) => {
      // ========= Set Custom Ballo Bhai Font in Pdf =========
      loadCustomFont(pdf);
      pdf.setFontType("normal");
      pdf.setFont("BalooBhai");
      // ========= Set Custom Ballo Bhai Font in Pdf =========

      pdf = await addLogoAndReportName(pdf);
      pdf = await addPageNumbers(pdf);
      return pdf;
    });
  return dataRes;
};

const isToday = (date) => {
  const today = new Date();
  return date.toDateString() === today.toDateString();
};

const isYesterday = (date) => {
  const yesterday = new Date();
  yesterday.setDate(yesterday.getDate() - 1);
  return date.toDateString() === yesterday.toDateString();
};

/**
 *  FOR FILTER GRAPH TYPE IN DASHBOARD
 * @param {Date} date date to check and modify
 */

export const InquiryDateFormateWise = (date) => {
  const formateDate = new Date(date);
  const today = new Date();

  let dateFormate = "";

  if (isToday(formateDate)) {
    dateFormate = "Today";
  } else if (isYesterday(formateDate)) {
    dateFormate = "Yesterday";
  } else {
    const dateYear = formateDate.getFullYear();
    const todayYear = today.getFullYear();

    dateFormate = `${formateDate.toLocaleString("default", {
      month: "short",
    })}  ${formateDate.getDate()}  ${
      dateYear === todayYear
        ? ""
        : formateDate.toLocaleDateString("default", {
            year: "numeric" || "2-digit",
          })
    }`;
  }

  return dateFormate;
};

/**
 *  For filter graph response based on type
 * @param {Object} data
 * @param {String} type
 */

export const FilterGraphType = (data, type = "bar") => {
  switch (type) {
    case "bar":
      return _.filter(data, (itm) => {
        if (
          itm?.graph_type?.toLowerCase()?.includes("bar") ||
          itm?.question?.includes("bar_chart")
        ) {
          return itm?.data || itm.response;
        }
      });
    case "line":
      return _.filter(data, (itm) => {
        if (
          (itm?.graph_type?.includes("line") &&
            !itm?.graph_type?.includes("dual_line")) ||
          (itm?.question?.includes("line_chart") &&
            !itm?.question?.includes("dual_line_chart"))
        ) {
          return itm?.data || itm.response;
        }
      });
    case "dual_line":
      return _.filter(data, (itm) => {
        if (
          itm?.graph_type?.includes("dual_line") ||
          itm?.question?.includes("dual_line_chart")
        ) {
          return itm?.data || itm.response;
        }
      });
    case "pie":
      return _.filter(data, (itm) => {
        if (
          itm?.graph_type?.includes("pie") ||
          ["pie_chart", "pieChart", "PieChart", "pie", "piechart"].includes(
            itm?.graph_type
          )
        ) {
          return itm?.data || itm.response;
        }
      });
    case "area":
      return _.filter(data, (itm) => {
        if (
          itm?.graph_type?.toLowerCase()?.includes("area") ||
          itm?.question?.includes("area_chart")
        ) {
          return itm?.data || itm.response;
        }
      });
    case "pareto":
      return _.filter(data, (itm) => {
        if (
          itm?.graph_type?.toLowerCase()?.includes("pareto") ||
          itm?.question?.includes("pareto_chart")
        ) {
          return itm?.data || itm.response;
        }
      });

    case "shankey":
      return _.filter(data, (itm) => {
        if (
          itm?.graph_type?.toLowerCase()?.includes("shankey") ||
          itm?.question?.includes("shankey_chart")
        ) {
          return itm?.data || itm.response;
        }
      });
    default:
      return _.filter(data, (itm) => {
        if (
          itm?.graph_type?.toLowerCase()?.includes("bar") ||
          itm?.question?.includes("bar_chart")
        ) {
          return itm?.data || itm.response;
        }
      });
  }
};

/**
 ** Format and return json string data for show in UI **
 * @param {Array} data data of reponse of api
 */
export const ConverChatData = (data) => {
  let item = typeof data;
  if (_.isArray(data)) {
    item = "array";
  }

  switch (item) {
    case "string":
      return (
        <div>
          {data
            .replace(`"response":`, "")
            .replace("{", "")
            .replace("}", "")
            .replaceAll(`"`, "")}
        </div>
      );
    case "object":
      return (
        <Box>
          {_.map(data, (value, key) => {
            return (
              <pre>
                {key}: {JSON.stringify(value, null, 2)}
              </pre>
            );
          })}
        </Box>
      );
    case "array":
      return (
        <Box>
          <List>
            {data.map((value, idx) => {
              if (_.isString(value)) {
                return <ListItem key={idx}>{value}</ListItem>;
              }
              return (
                <ListItem key={idx}>
                  <pre>{JSON.stringify(value, null, 2)}</pre>
                </ListItem>
              );
            })}
          </List>
        </Box>
      );
    default:
      return <div>{data}</div>;
  }
};

export const SendErrorToSlack = async (payload, section, data = {}) => {
  try {
    let datas = data?.body ? Object.fromEntries(data?.body?.entries()) : {};

    data = { ...data, body: datas };

    const url = `${process.env.REACT_APP_API_BASE_URL}/account/error-slack/`;

    const modifiedPayload = {
      blocks: [
        {
          type: "section",
          text: {
            type: "mrkdwn",
            text: `:warning: *Hold up!* An error just  FRONT END photobombed the *${section}* zone. Our code's taking unexpected selfies with bugs! :camera_with_flash:`,
          },
        },
        {
          type: "section",
          text: {
            type: "mrkdwn",
            text:
              " *Error Snapshot* : ```json\n" +
              JSON.stringify(payload, null, 2) +
              "\n```",
          },
        },
        {
          type: "section",
          text: {
            type: "mrkdwn",
            text: "```json\n" + JSON.stringify(data, null, 2) + "\n```",
          },
        },
        {
          type: "context",
          elements: [
            {
              type: "mrkdwn",
              text: "Time to debug with style! Let's put on our error-fighting capes and show those bugs who's boss. :superhero::computer:",
            },
          ],
        },
      ],
      username: "Mayank Patel",
    };

    await axios.post(url, { payload: modifiedPayload });
  } catch (e) {
    console.error("🚀 ~ SendErrorToSlack ~ e:", e);
  }
};
