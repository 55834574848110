import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { commonReduxType, removeToken } from "../utils/common";
import { post, put } from "../api/api";
import { openAlert } from "./alertSlice";
import {
  FILE_UPLOAD_MESSSAGE,
  FORGOT_PASSWORD_MESSSAGE,
  LOGIN_MESSAGE,
  REGISTER_MESSAGE,
  RESET_MESSAGE_MESSAGE,
} from "../const/alertMessage";

// ========== INITIALSTATE ==========
const initialState = {
  login: commonReduxType,
  register: commonReduxType,
  reset_password: commonReduxType,
  forgot_password: commonReduxType,
  file_upload: commonReduxType,
};

// ========== API CALL LOGIN ==========
export const login = createAsyncThunk(
  "login",
  async (data, { dispatch, rejectWithValue }) => {
    try {
      const response = await post("/account/login/", "", data);
      dispatch(openAlert({ message: LOGIN_MESSAGE[0], severity: "success" }));
      return response;
    } catch (error) {
      const message =
        error?.response?.status === 401
          ? LOGIN_MESSAGE[1]
          : error?.response?.status === 403
          ? LOGIN_MESSAGE[3]
          : LOGIN_MESSAGE[1];
      if (![401, 403].includes(error?.response?.status)) {
        dispatch(
          openAlert({
            message: message,
            severity: "error",
          })
        );
      }
      throw rejectWithValue(error.response ? message : error.message);
    }
  }
);

// ========== API CALL REGISTER ==========
export const register = createAsyncThunk(
  "register",
  async (data, { dispatch, rejectWithValue }) => {
    try {
      const response = await post("/account/register/", "", data);
      dispatch(
        openAlert({ message: REGISTER_MESSAGE[0], severity: "success" })
      );
      return response;
    } catch (error) {
      const message = error?.response?.data?.email || REGISTER_MESSAGE[1];
      if (!error?.response?.data) {
        dispatch(
          openAlert({
            message: message,
            severity: "error",
          })
        );
      }
      throw rejectWithValue(error.response ? message : error.message);
    }
  }
);

// ========== API CALL RESET PASSWORD ==========
export const resetPassword = createAsyncThunk(
  "resetPassword",
  async (data, { dispatch, rejectWithValue }) => {
    try {
      const response = await post("/account/reset-password/", "", data);
      dispatch(
        openAlert({ message: RESET_MESSAGE_MESSAGE[0], severity: "success" })
      );
      return response;
    } catch (error) {
      const message = [404].includes(error?.response?.status)
        ? RESET_MESSAGE_MESSAGE[1]
        : RESET_MESSAGE_MESSAGE[2];
      dispatch(
        openAlert({
          message: message,
          severity: "error",
        })
      );
      throw rejectWithValue(error.response ? message : error.message);
    }
  }
);

// ========== API CALL FORGOT PASSWORD ==========
export const forgotPassword = createAsyncThunk(
  "forgotPassword",
  async ({ formData, slug }, { dispatch, rejectWithValue }) => {
    try {
      const response = await post(
        `/account/forgot-password/${slug}`,
        "",
        formData
      );
      dispatch(
        openAlert({ message: FORGOT_PASSWORD_MESSSAGE[0], severity: "success" })
      );
      return response;
    } catch (error) {
      const message = [404].includes(error?.response?.status)
        ? FORGOT_PASSWORD_MESSSAGE[2]
        : error?.response?.data?.error || FORGOT_PASSWORD_MESSSAGE[1];
      dispatch(
        openAlert({
          message: message,
          severity: "error",
        })
      );
      throw rejectWithValue(error.response ? message : error.message);
    }
  }
);

// ========== API CALL UPLOAD PROFILES FILES ==========
export const uploadProfileorLogoFiles = createAsyncThunk(
  "uploadProfileorLogoFiles",
  async (formData, { dispatch, rejectWithValue }) => {
    try {
      const response = await put(`/account/profile/`, "", formData);
      dispatch(
        openAlert({ message: FILE_UPLOAD_MESSSAGE[0], severity: "success" })
      );
      return response;
    } catch (error) {
      const message = error?.response?.data?.error || FILE_UPLOAD_MESSSAGE[1];
      dispatch(
        openAlert({
          message: message,
          severity: "error",
        })
      );
      throw rejectWithValue(error.response ? message : error.message);
    }
  }
);

// ========== API CALL UPDATE TOUR DETAILS FILES ==========
export const userTourUpdate = createAsyncThunk(
  "userTourUpdate",
  async (formData, { dispatch, rejectWithValue }) => {
    try {
      const response = await put(`/account/profile/`, "", formData);
      return response;
    } catch (error) {
      const message = error?.response?.data?.error || "Something is wrong while update profile";
      dispatch(
        openAlert({
          message: message,
          severity: "error",
        })
      );
      throw rejectWithValue(error.response ? message : error.message);
    }
  }
);

// ========== AUTH REDUCER ==========
const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    // Regular reducers can be added here if needed
    logout: (state) => {
      state.login = {
        loading: false,
        data: {},
        error: null,
      };
      removeToken();
      localStorage.removeItem("selectedData");
      localStorage.removeItem("inquiryDataSource");
      localStorage.removeItem("is_tour_complete");
      window.location.replace("/login");
    },
    emptyLogin: (state) => {
      state.login = {
        ...state.login,
        is_success: false,
        loading: false,
        error: null,
        data: {},
      };
    },
    emptyResetPassword: (state) => {
      state.reset_password = {
        ...state.reset_password,
        is_success: false,
        loading: false,
        error: null,
        data: {},
      };
    },
    emptyForgotPassword: (state) => {
      state.forgot_password = {
        ...state.forgot_password,
        is_success: false,
        loading: false,
        error: null,
        data: {},
      };
    },
    emptyRegister: (state) => {
      state.register = {
        ...state.register,
        is_success: false,
        loading: false,
        error: null,
        data: {},
      };
    },
    emptyFileUpload: (state) => {
      state.file_upload = {
        ...state.file_upload,
        is_success: false,
        loading: false,
        error: null,
        data: {},
      };
    },
    // Reset other relevant state slices to their initial state if needed
  },
  extraReducers: (builder) => {
    //  =================================== LOGIN CASES ===================================
    builder.addCase(login.pending, (state, action) => {
      state.login = {
        ...state.login,
        loading: true,
        is_success: false,
        error: null,
      };
    });
    builder.addCase(login.fulfilled, (state, action) => {
      state.login = {
        ...state.login,
        is_success: true,
        loading: false,
        data: action.payload,
      };
    });
    builder.addCase(login.rejected, (state, action) => {
      state.login = {
        ...state.login,
        is_success: false,
        loading: false,
        error: action.payload,
      };
    });
    //  =================================== LOGIN CASES ===================================

    //  =================================== REGISTER CASES ===================================
    builder.addCase(register.pending, (state, action) => {
      state.register = {
        ...state.register,
        is_success: false,
        loading: true,
        error: null,
      };
    });
    builder.addCase(register.fulfilled, (state, action) => {
      state.register = {
        ...state.register,
        is_success: true,
        loading: false,
        data: action.payload,
      };
    });
    builder.addCase(register.rejected, (state, action) => {
      state.register = {
        ...state.register,
        is_success: false,
        loading: false,
        error: action.payload,
      };
    });
    //  =================================== REGISTER CASES ===================================

    //  =================================== RESET PASSWORD CASES ===================================
    builder.addCase(resetPassword.pending, (state, action) => {
      state.reset_password = {
        ...state.reset_password,
        is_success: false,
        loading: true,
        error: null,
      };
    });
    builder.addCase(resetPassword.fulfilled, (state, action) => {
      state.reset_password = {
        ...state.reset_password,
        is_success: true,
        loading: false,
        data: action.payload,
      };
    });
    builder.addCase(resetPassword.rejected, (state, action) => {
      state.reset_password = {
        ...state.reset_password,
        is_success: false,
        loading: false,
        error: action.payload,
      };
    });
    //  =================================== RESET PASSWORD CASES ===================================

    //  =================================== FORGOT PASSWORD CASES ===================================
    builder.addCase(forgotPassword.pending, (state, action) => {
      state.forgot_password = {
        ...state.forgot_password,
        is_success: false,
        loading: true,
        error: null,
      };
    });
    builder.addCase(forgotPassword.fulfilled, (state, action) => {
      state.forgot_password = {
        ...state.forgot_password,
        is_success: true,
        loading: false,
        data: action.payload,
      };
    });
    builder.addCase(forgotPassword.rejected, (state, action) => {
      state.forgot_password = {
        ...state.forgot_password,
        is_success: false,
        loading: false,
        error: action.payload,
      };
    });
    //  =================================== FORGOT PASSWORD CASES ===================================

    //  =================================== UPLOAD FILES CASES ===================================
    builder.addCase(uploadProfileorLogoFiles.pending, (state, action) => {
      state.file_upload = {
        ...state.file_upload,
        is_success: false,
        loading: true,
        error: null,
      };
    });
    builder.addCase(uploadProfileorLogoFiles.fulfilled, (state, action) => {
      state.file_upload = {
        ...state.file_upload,
        is_success: true,
        loading: false,
        data: action.payload,
      };
    });
    builder.addCase(uploadProfileorLogoFiles.rejected, (state, action) => {
      state.file_upload = {
        ...state.file_upload,
        is_success: false,
        loading: false,
        error: action.payload,
      };
    });
    //  =================================== UPLOAD FILES CASES ===================================
  },
});

export const {
  logout,
  emptyLogin,
  emptyResetPassword,
  emptyForgotPassword,
  emptyRegister,
  emptyFileUpload,
} = authSlice.actions;
export default authSlice.reducer;
