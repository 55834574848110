import React, { lazy } from "react";
import { createBrowserRouter } from "react-router-dom";
import Loadable from "./component/Loader";
import {
  LoginPreventRoute,
  PrivateRoute,
  RoleBasePreventRoute,
  USER_ROLE,
} from "./const";

// ========================= COMPONENT =========================
const LoginPage = Loadable(lazy(() => import("./pages/authentication/Login")));
const ResetPasswordPage = Loadable(
  lazy(() => import("./pages/authentication/ResetPassword"))
);
const RegiserPage = Loadable(
  lazy(() => import("./pages/authentication/Register"))
);
const ForgotPasswordPage = Loadable(
  lazy(() => import("./pages/authentication/ForgotPassword"))
);
const PageNotFound = Loadable(
  lazy(() => import("./pages/authentication/PageNotFound"))
);
const Container = Loadable(lazy(() => import("./component/Container")));
const Dashboard = Loadable(lazy(() => import("./pages/Dashboard")));
const Inquiry = Loadable(lazy(() => import("./pages/Inquiry")));
const WrittenReport = Loadable(lazy(() => import("./pages/WrittenReport")));
const ScheduledReport = Loadable(lazy(() => import("./pages/ScheduledReport")));

const PricePage = Loadable(lazy(() => import("./pages/Price")));
const ProfilePage = Loadable(lazy(() => import("./pages/Profile")));
const LandingPage = Loadable(lazy(() => import("./pages/LandingPage")));
const VerifySubUserLinkPage = Loadable(
  lazy(() => import("./pages/VerifySubUserLink"))
);

const DataSourcePage = Loadable(lazy(() => import("./pages/DataSource")));
const AdministrationPage = Loadable(
  lazy(() => import("./pages/administration"))
);
const HelpPage = Loadable(lazy(() => import("./pages/Help")));
const SubsriptionsOverPage = Loadable(
  lazy(() => import("./pages/SubscriptionOver"))
);
const StripeSuccessPage = Loadable(lazy(() => import("./pages/StripeSuccess")));
const UserAgreementPage = Loadable(lazy(() => import("./pages/UserAgreement")));

// ========================= ROUTES =========================
const route = createBrowserRouter([
  {
    path: "/",
    // element: <LandingPage />,
    element: <LoginPreventRoute component={LandingPage} />,
  },
  {
    path: "/",
    element: <Container />,
    children: [
      {
        path: "/dashboard",
        element: (
          <PrivateRoute
            component={Dashboard}
            role={[USER_ROLE.ADMIN, USER_ROLE.MEMBER]}
          />
        ),
      },
      {
        path: "/inquiry",
        element: (
          <PrivateRoute
            component={Inquiry}
            role={[USER_ROLE.ADMIN, USER_ROLE.MEMBER]}
          />
        ),
      },
      {
        path: "/written-report",
        element: (
          <PrivateRoute
            component={WrittenReport}
            role={[USER_ROLE.ADMIN, USER_ROLE.MEMBER]}
          />
        ),
      },
      {
        path: "/scheduled-report",
        element: (
          <PrivateRoute
            component={ScheduledReport}
            role={[USER_ROLE.ADMIN, USER_ROLE.MEMBER]}
          />
        ),
      },
      {
        path: "/profile",
        element: (
          <PrivateRoute
            component={ProfilePage}
            role={[USER_ROLE.ADMIN, USER_ROLE.MEMBER]}
          />
        ),
      },
      {
        path: "/data-source",
        element: (
          <PrivateRoute component={DataSourcePage} role={[USER_ROLE.ADMIN]} />
        ),
      },
      {
        path: "/administration",
        element: (
          <PrivateRoute
            component={AdministrationPage}
            role={[USER_ROLE.ADMIN]}
          />
        ),
      },
    ],
  },
  {
    path: "/price",
    element: (
      <RoleBasePreventRoute component={PricePage} role={[USER_ROLE.ADMIN]} />
    ),
  },
  {
    path: "/login",
    element: <LoginPreventRoute component={LoginPage} />,
  },
  {
    path: "/reset-password",
    element: <ResetPasswordPage />,
  },
  {
    path: "/stripe/success",
    element: (
      <RoleBasePreventRoute
        component={StripeSuccessPage}
        role={[USER_ROLE.ADMIN]}
      />
    ),
  },
  {
    path: "/register",
    element: <RegiserPage />,
  },
  {
    path: "/forgot-password",
    element: <ForgotPasswordPage />,
  },
  {
    path: "/privacy-policy",
    element: <UserAgreementPage />,
  },
  {
    path: "/help",
    element: <HelpPage />,
  },
  {
    path: "/subscriptions",
    element: (
      <RoleBasePreventRoute
        component={SubsriptionsOverPage}
        role={[USER_ROLE.ADMIN]}
      />
    ),
  },
  {
    path: "/verify-link",
    element: <VerifySubUserLinkPage />,
  },
  {
    path: "*",
    element: <PageNotFound />,
  },
]);

export default route;
